.career-view {
    padding: 45px 0px 50px 0px;
    min-height: 450px;
}
.career-view .job-title {
    font-size: 15px;
    font-weight: 500;
}
.career-view .card {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-color: #fff;
    box-shadow: 2px 4px 70px rgba(39, 123, 156, 0.2);
    border-radius: 14px;
}
.career-view .ado-id {
    color: #a0a0a0;
}
.career-view .post-date {
    color: #a0a0a0;
    /* font-style: italic; */
    font-size: 14px;
}
.career-view .card-desc {
    font-weight: 500;
    color: #616060;
}
.career-view .btn-color {
    background-color: teal !important;
    border-color: teal !important;
}
.empty-span {
    visibility: hidden;
}