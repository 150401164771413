.dw_logo {
    height: 36px;
    border: 2px solid teal;
}
/* .header-sec {
    margin-bottom: 80px;
} */
.header-sec .header-menu {
    padding: 12px;
    border-bottom: 1px solid teal;
}
.header-menu .register_btn {
    /* color: teal;
    border: 1px solid teal;
    margin: 4px;
    padding: 6px;
    padding-bottom: 8px;
    line-height: 1.2;
    text-decoration: none; */
    position: absolute;
    right: 10px;
}
.header-menu .logout_btn {
    background: teal;
    color: #fff;
    border-radius: 5px;
    border: none;
    padding: 6px 20px;
    padding-bottom: 10px;
}
.header-menu .register_btn:hover {
    color: #fff;
    background-color: teal;
}
.mobile-menu{
    display: none;
}
.mobile-menu i{
    font-size: 30px;
    color: teal;
}
.header-menus .nav .nav-item .nav-link {
    color: teal;
    font-size: 14px;
    border-radius: 0 !important;
    font-weight: 500;
}
.header-menus .nav .nav-item .nav-link.active {
    background-color: teal;
    color: #fff;
    text-decoration: none;
}
.header-menus .nav .nav-item .nav-link:hover {
    background-color: teal;
    color: #fff;
}
@media (min-width: 350px) and (max-width: 550px) {
    .dw_logo {
        max-width: 45% !important;
    }
}
@media (min-width: 550px) and (max-width: 768px) {
    .dw_logo {
        max-width: 30% !important;
    }
}