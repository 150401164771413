.org-section .form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
.org-section .form-field label {
  margin-right: 1rem;
  text-align: left;
  color:teal;
}
/* .org-section .form-field textarea {
  border: 1px solid teal;
  border-radius: 5px;
  height: 75px;
  width: 100%;
  padding: 10px;
} */
.org-section .error {
  color: red;
  font-size: 13px;
  margin-top: -0.5rem;
  float: right;
  margin-bottom: 0.5rem;
}
.org-section .btn-div {
  text-align: right;
}
.org-section .register_btn {
  color: #fff;
  border: 1px solid black;
  padding: 8px 35px;
  line-height: 1.2;
  text-decoration: none;
  background: teal;
  border-radius: 5px;
}
.org-section .register_btn:hover {
    color: teal;
    background-color: #ffffff;
}
.org-section .login_link {
    font-size: 14px;
    color: teal;
    font-weight: 400;
    text-align: center;
    margin: 30px 0px;
}
.org-section .login_btn {
    text-decoration: none;
    color: teal;
    font-weight: 600;
    font-style: italic;
}
.org-section .error-message {
    color: red;
    font-size: 13px;
}
.org-section .success-message {
  text-align: center;
  margin-bottom: 25px;
  color: #02b53f;
  font-size: 17px;
}
.org-list-table table {
  width: 100%;
  border-collapse: collapse;
}
.org-list-table tr .table-col-name {
  flex: 15%;
  width: 15%;
}
.org-list-table tr .table-col-website {
  flex: 25%;
  width: 25%;
}
.org-list-table tr .table-col-address {
  flex: 25%;
  width: 25%;
}
.org-list-table tr .table-col-role {
  flex: 14%;
  width: 14%;
}
.org-list-table tr .table-col-enr-date {
  flex: 10%;
  width: 10%;
}
.org-list-table tr .table-col-end-date {
  flex: 10%;
  width: 10%;
}
.org-list-table .table-row {
  cursor: pointer;
  display: flex;
}
.org-list-table .table-head tr {
  display: flex;
}
/* .org-list-table  .table-body-container {
  height: 400px;
  overflow-y: auto;
} */
.verification-message {
  color: red;
  font-size: 13px;
  text-align: center;
  margin-bottom: 20px;
  
}