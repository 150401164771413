.website-sec .form-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .website-sec .form-field label {
    margin-right: 1rem;
    text-align: left;
    color:teal;
}
.website-sec .form-field textarea {
  border: 1px solid teal;
  border-radius: 25px;
  height: 75px;
  width: 100%;
  padding: 10px;
}
.website-sec .error {
  color: red;
  font-size: 13px;
  margin-top: -0.5rem;
  float: right;
  margin-bottom: 0.5rem;
}
.website-sec   .btn-div {
    text-align: center;
}
  .website-sec .register_btn {
    color: #fff;
    border: 1px solid black;
    margin: 4px;
    padding: 8px 35px;
    line-height: 1.2;
    text-decoration: none;
    background: teal;
    border-radius: 5px;
}
.website-sec .register_btn:hover {
    color: teal;
    background-color: #ffffff;
}
.website-sec .login_link {
    font-size: 14px;
    color: teal;
    font-weight: 400;
    text-align: center;
    margin: 30px 0px;
}
.website-sec .login_btn {
    text-decoration: none;
    color: teal;
    font-weight: 600;
    font-style: italic;
}
.website-sec .error-message {
    color: red;
    font-size: 13px;
}
.website-sec .success-message {
  text-align: center;
  margin-bottom: 25px;
  color: #02b53f;
  font-size: 17px;
}
.verification-message {
  color: red;
  font-size: 13px;
  text-align: center;
  margin-bottom: 20px;
  
}

.application-card {
  text-align: center;
  padding: 10px;
  height: 14vw;
  display: table-cell;
  vertical-align: middle;
  border: 2px solid teal;
  color: teal;
  position: relative;

}
.application-card.activated h3{
  color: #fff;
}
.application-card h3 {
  color: gray;
  font-size: 24px;
}
.application-card.activated {
  background: teal;
  color: #fff;
}
.application-card p {
  font-size: 14px;
}
.application-icons {
  position: absolute;
  top: 5px;
  right: 10px;
}
/* a.nav-link.active {
  text-decoration: underline;
  text-decoration-color: #ffffff;
  text-decoration-thickness: 3px;
  text-underline-offset: 0.8em!important;
} */
/* */
.website-sec .form-field select {
  flex-grow: 1;
  border-radius: 25px;
  box-shadow: none;
  border: 1px solid teal;
  height: 45px;
  padding: 10px;
  width: 100%;
}
.web-list-table table {
  width: 100%;
  border-collapse: collapse;
}
.web-list-table .table-col-name {
  flex: 25%;
  width: 25%;
}
.web-list-table .table-col-url {
  flex: 35%;
  width: 35%;
}
.web-list-table .table-col-address {
  flex: 30%;
  width: 30%;
}