.web-config-content-sec .form-field {
    margin-bottom: 20px;
}
.group-title  {
    line-height: 3;
    font-weight: 600;
    font-size: 19px;
    margin-top: 15px;
    text-decoration: underline;
}
.brdr_top {
    border-top: 2px solid teal;
}
.not-found {
    width: 100%;
    display: block;
    text-align: center;
}