.home-sec .home-content-sec {
    margin-top: 15%;
}
.home-content-sec .home-content {
    font-weight: 700;
    font-size: 41px;
    color: teal;
}
.home-content-sec .home-sign-up {
    text-decoration: none;
    color: teal;
    padding: 10px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    background-color: rgb(212 233 233);
}
.home-sign-up-sec {
    text-align: center;
    margin-top: 10%;
}