.prod-cat-content-sec .form-field {
    margin-bottom: 20px;
}
.category-dropdown {
    width: 100%;
}
.prod-list-table tr {
    display: flex;
}
.prod-list-table tr td {
    white-space: normal;
    word-break: break-word;
}
.prod-list-table tr .table-col-name {
    flex: 10%;
    width: 10%;
}
.prod-list-table tr .table-col-price {
    flex: 5%;
    width: 5%;
}
.prod-list-table tr .table-col-image {
    flex: 35%;
    width: 35%;
}
.required-label {
    color: red;
    /* Additional styling properties */
}
.image-preview-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .image-preview {
    position: relative;
    margin: 5px;
    max-width: 150px;
    max-height: 150px;
  }
  
  .preview-image {
    width: 150px;
    height: 150px;
    border: 1px solid #ccc;
  }
  
  .remove-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 2px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }
  
  .remove-icon i {
    color: red; /* Change the color as needed */
  }
  
  .img-preview {
    border: 1px solid teal;
    height: 129px;
    width: 200px;
    margin-right: 10px;
  }
  .overlay-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  