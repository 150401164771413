.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.ck-powered-by {
  display: none;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, 
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-color: teal;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: teal !important;
}
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, 
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.head-sec {
  display: flex;
}
.inner-page-title {
  margin: auto;
  margin-bottom: 35px;
  color: teal;
}
.add-btn {
  text-decoration: none;
  border: 1px solid teal;
  color: teal;
  height: 38px;
  padding: 5px 10px;
  border-radius: 5px;
}
.add-btn:hover {
  background-color: teal;
  color: #fff;
}
.no-data {
  text-align: center;
}
.error-msg {
  color: red;
  font-size: 13px;
  position: relative;
  top: -19px;
}
.form-field input {
  flex-grow: 1;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid teal;
  height: 44px;
  padding: 10px;
  width: 100%;
}
.form-field textarea {
  border: 1px solid teal;
  border-radius: 5px;
  height: 75px;
  width: 100%;
  padding: 10px;
}
.submit_btn {
  color: #fff;
  border: 1px solid black;
  margin: 4px;
  padding: 8px 35px;
  line-height: 1.2;
  text-decoration: none;
  background: teal;
  border-radius: 5px;
}
.submit_btn:hover {
  color: teal;
  background-color: #ffffff;
}
.form-submit-btn {
  float: right;
}
.table-body-container {
  max-height: 56vh;
  overflow: auto;
}
.mt-30 {
  margin-top: 30px;
}
.success-msg {
  text-align: center;
  margin: auto;
  color: #02b53f;
  font-size: 17px;
  margin-right: 25px;
}
.layout-sec {
  width: 80%;
  overflow: hidden;
  margin-bottom: 30px;
}
.website-dropdown, .category-dropdown {
  width: 100%;
  margin-bottom: 18px;
  padding: 10px;
  border-color: teal;
  border-radius: 5px;
}
.mand-star {
  color: red;
  font-weight: 600;
}
.desc-quil {
  border: 1px solid teal;
  border-radius: 5px;
}
.desc-quil .ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid teal;
}
.desc-quil .ql-container.ql-snow {
  border: none;
}
.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.section-title {
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  /* color: #b81b28; */
  font-family: "Jost", sans-serif;
}
.section-title::before {
  content: '';
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.section-title::after {
  content: '';
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: teal;
  bottom: 0;
  left: calc(50% - 20px);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
