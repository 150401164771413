.career-job-posting {
    padding: 45px 0px 50px 0px;
    min-height: 450px;
}
.career-job-posting .ado-id {
    color: #a0a0a0;
    font-size: 14px;
    margin-left: 25px;
    font-style: italic;
}
.apply-btn {
    margin-bottom: 20px;
    height: 30px;
    padding-top: 4px !important;
    font-size: 14px;
}
.apply-btn.top {
    position: relative;
    float: right;
    top: -85px;
}
.apply-btn.bottom {
    position: relative;
    float: left;
    bottom: -45px;
}
.career-job-posting .post-date {
    color: #a0a0a0;
    font-size: 14px !important;
}
.career-job-posting .btn-color {
    background-color: teal !important;
    border-color: teal !important;
    color: #fff;
}