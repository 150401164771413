.prod-cat-content-sec .form-field {
    margin-bottom: 20px;
}
.app-chip {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    border: 1px solid teal;
    border-radius: 5px;
}
.app-chip .text-area {
    width: 97%;
    padding: 8px;
    font-size: 16px;
    border: none;
    outline: none;
    resize: none;
}
.app-chip .chip-list {
    display: flex;
    flex-wrap: wrap;
}
.app-chip .chip {
    background-color: #e0e0e0;
    border-radius: 16px;
    padding: 6px 12px;
    margin: 4px;
}
.app-chip .chip-close {
    margin-left: 5px;
    cursor: pointer;
}
/* .website-dropdown {
    width: 100%;
    margin-bottom: 18px;
    padding: 10px;
    border-color: teal;
    border-radius: 5px;
} */
.img-preview {
    border: 1px solid teal;
    height: 150px;
    width: 200px;
    margin-right: 10px;
}
.img-preview img {
    height: 127px;
    width: 100%;
}
.form-field input[type='file'] {
    border: none;
    height: auto;
}
.img-preview .close-btn {
    position: relative;
    left: 111px;
    top: 7px;
    display: block;
    color: red;
    margin-top: 10px;
}
.cat-list-table tr {
    display: flex;
}
.cat-list-table tr td {
    white-space: normal;
    word-break: break-word;
}
.cat-list-table tr .table-col-name {
    flex: 10%;
    width: 10%;
}
.cat-list-table tr .table-col-title {
    flex: 25%;
    width: 25%;
}
.cat-list-table tr .table-col-url {
    flex: 25%;
    width: 25%;
}