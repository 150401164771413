/* Sidebar css */
.dashboard-container {
    display: flex;
    min-height: 91.6vh;
  }
  
  .sidebar {
    width: 20%;
    background: teal;
  }
  
  .header {
    background-color: #333;
    color: #fff;
    padding: 16px;
  }
 .main-content {
    flex: 1;
    padding: 20px;
    width: 80vw;
  }
.user-panel img {
    height: auto;
    width: 3.2rem;
}
.elevation-2 {
    box-shadow: 0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23)!important;
}
.img-circle {
    border-radius: 50%;
}
img {
    vertical-align: middle;
    border-style: none;
}
.sidebar ul {
   padding: 0;
}
.sidebar ul li {
    padding: 10px;
    list-style-type: none;
    font-size: 14px;
}
.side-menu li:has(a.active), .side-menu li:hover {
  background-color: #fff;
  color: teal;
  font-weight: 500;
}
.sidebar .app-sub-menu {
  margin-left: 25px;
}
.sidebar .app-sub-menu-2 {
  margin-left: 40px;
}