#contact_us {
    padding-top: 50px;
    padding-bottom: 50px;
}
#contact_us .start-box {
    background-image: url('../../Assets/Images/Intersect.png');
    background-size: cover;
    box-sizing: border-box;
    height: 450px;
    border: 1px solid #277b9c;
    border-radius: 30px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#contact_us .start-box h3 {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #2d3134;
    margin-top: 20px;
    margin-bottom: 35px;
}
#contact_us .start-box p,#contact_us .start-box li {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
    color: #5b5f62;
}
#contact_us .email_id_img {
    width: 83%;
    margin-bottom: 5px;
}
#contact_us .start-box button {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    box-sizing: border-box;
    margin-top: 100px;
    width: 381px;
    height: 77px;
    color: #277b9c;
    background: #fff;
    border: 1px solid #277b9c;
    box-shadow: 0px 4px 4px rgba(39, 123, 156, 0.25), inset 2px 4px 4px rgba(39, 123, 156, 0.45);
    border-radius: 15px;
}
#contact_us .start-box .cont-right li {
    list-style: none;
}
#contact_us .start-box .fa-solid {
    padding-right: 15px;
}
#contact_us .addr_cont {
    padding-left: 30px;
}
#contact_us .contact-left-img {
    /* position: absolute;
    left: 44px; */
    position: relative;
    left: -39px;
}
#contact_us .contact_logo {
    width: 75%;
}
#contact_us .dw_l {
    position: relative;
    top: 38px;
    width: 300px;
}
#contact_us .svr_l {
    width: 246px;
    margin-left: 20px;
}
#contact_us .meit_l {
    position: relative;
    /* top: 24px; */
    top: 28px;
    width: 210px;
}
@media (min-width: 200px) and (max-width: 647px) {
    #contact_us .start-box {
        height: auto;
    }
    .contact-left-img {
        display: none;
    }
    #contact_us .email_id_img {
        width: 41%;
        margin-bottom: 5px;
    }
    #contact_us .our-partner {
        text-align: center;
    }
}
@media (min-width: 648px) and (max-width: 997px) {
    #contact_us .contact-left-img {
        display: none;
    }
}
@media (min-width: 998px) and (max-width: 1200px) {
    #contact_us .contact-left-img {
        left: -96px;
    }
}
@media (min-width: 1201px) and (max-width: 1400px) {
    #contact_us .contact-left-img {
        left: -68px;
    }
}