.register-sec {
    margin-top: 100px;
}
.register-sec .form-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }
  .register-sec .form-field label {
    margin-right: 1rem;
    text-align: left;
    color:teal;
  }
  /* .register-sec .form-field input {
    flex-grow: 1;
    border-radius: 25px;
    box-shadow: none;
    border: 1px solid teal;
    height: 35px;
    padding: 10px;
    width: 100%;
  } */
  .register-sec .error {
    color: red;
    font-size: 13px;
    margin-top: -0.5rem;
    float: right;
    margin-bottom: 0.5rem;
  }
  .register-sec .btn-div {
    text-align: right;
}
  .register-sec .register_btn {
    color: #fff;
    border: 1px solid black;
    margin: 4px;
    padding: 8px 35px;
    line-height: 1.2;
    text-decoration: none;
    background: teal;
    border-radius: 5px;
}
.register-sec .register_btn:hover {
    color: teal;
    background-color: #ffffff;
}
.register-sec .login_link {
    font-size: 14px;
    color: teal;
    font-weight: 400;
    text-align: center;
    /* margin: 30px 0px; */
}
.register-sec .login_btn {
    text-decoration: none;
    color: teal;
    font-weight: 600;
    font-style: italic;
}
.register-sec .error-message {
  text-align: left;
  margin-bottom: 25px;
  color: #ff0000;
  font-size: 17px;
}
.register-sec .success-message {
  text-align: left;
  margin-bottom: 25px;
  color: #02b53f;
  font-size: 17px;
}
.verification-message {
  color: red;
  font-size: 13px;
  text-align: center;
  margin-bottom: 20px;
  
}
.password-input-container {
  position: relative;
  width: 100%;
}

.password-input-container input[type="password"] {
  padding-right: 30px;
  width: 100%;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
  color: teal;
}

.password-toggle-icon svg {
  width: 20px;
  height: 20px;
}
