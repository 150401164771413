body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media (max-width: 767.98px) {
  .mobile-menu{
    display: block !important;
    width: 50px !important;
    height: 50px;
    border: none;
    background-color: #fff;
    position: absolute;
    right: 30px;
    top: 10px;
  }

  .header-menus{
    transition: all 0.5s ease;
    position: fixed;
    width: 80%;
    height: 100vh;
    left: -100%;
    top: 0;
    z-index: 2;
    background-color: #fff;
  }

  .showmenu{
  left: 0  !important;
  }

  .header-menus .nav{
    display: block;
  }

  .header-menus .nav-item{
    display: block;
  }

  .header-menus .dropdown{
    display: block;
  }
}
.pl-0 {
  padding-left: 0 !important;
}
/* Add a box shadow to indicate focus for keyboard users */
button.page-link:focus {
  /* box-shadow: 0 0 5px 2px rgba(0, 0, 255, 0.5); */
  outline: none;
}
.pagination-nav {
  justify-content: center;
  display: flex;
  margin-top: 25px;
}
/* 277b9c */
.page-link.active, .active > .page-link {
  color: #fff !important;
  background-color: teal !important;
  border-color: teal !important;
}
.page-link {
  color: teal !important;
}