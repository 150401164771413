.qa-list-sec .application-card {
  text-align: center;
  padding: 10px;
  height: 14vw;
  display: table-cell;
  vertical-align: middle;
  border: 2px solid teal;
  color: teal;
  position: relative;

}
.qa-list-sec .app-col {
  display: table;
  text-decoration: none;
}
.qa-list-sec .application-card.activated h3{
  color: #fff;
}
.qa-list-sec .application-card h3 {
  color: gray;
  font-size: 24px;
}
.qa-list-sec .application-card.activated {
  background: teal;
  color: #fff;
}
.qa-list-sec .application-card p {
  font-size: 14px;
}
.qa-list-sec .application-icons {
  position: absolute;
  top: 5px;
  right: 10px;
}
.qa-list-sec a.nav-link.active {
  text-decoration: underline;
  text-decoration-color: #ffffff;
  text-decoration-thickness: 3px;
  text-underline-offset: 0.8em!important;
}
/* */
